.box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: top;
    text-align: center;
  }
  
  .box > * {
    padding: 1rem;
    width: 100%;
  }
  
  .box p {
    color: #555; /* Dark grey color for paragraph text */
  }
  
  /* Small screens */
  @media (max-width: 767px) {
    .box {
      border-bottom: 1px solid  #007bff;
    }
    .box4 {
      border-bottom: none;
    }
  }
  
  /* Medium and large screens */
  @media (min-width: 768px) {
    .box1, .box2, .box3 {
      /* primary blue */
      border-right: 1px solid  #007bff;
    }
  }
  
  /* Margin for vertical layout on small screens */
  @media (max-width: 767px) {
    .box:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  
  /* Remove right margin from the first column on medium and large screens */
  @media (min-width: 768px) {
    .box1, .box3 {
      padding-right: 0;
    }
  }